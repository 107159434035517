<template>
  <div class="button_group">
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP)">프리미엄</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE)">슈퍼리그</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP)">유로컵</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP)">월드컵</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK)">골든힐</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK}"
            @click="changeKind(leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK)">힐사이드</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_POWERBALL}"
            @click="changeKind(leisureConst.LEISURE_KIND_POWERBALL)">파워볼</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_POWERSADALI}"
            @click="changeKind(leisureConst.LEISURE_KIND_POWERSADALI)">파워사다리</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_SPEEDKINO}"
            @click="changeKind(leisureConst.LEISURE_KIND_SPEEDKINO)">스피드키노</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_KINOSADALI}"
            @click="changeKind(leisureConst.LEISURE_KIND_KINOSADALI)">키노사다리</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_NAMED_DALIDALI}"
            @click="changeKind(leisureConst.LEISURE_KIND_NAMED_DALIDALI)">N다리다리</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_NAMED_SADALI}"
            @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SADALI)">N사다리</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI}"
            @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI)">NS사다리</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_NAMED_SNAIL}"
            @click="changeKind(leisureConst.LEISURE_KIND_NAMED_SNAIL)">N달팽이</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_FX_1M}"
            @click="changeKind(leisureConst.LEISURE_KIND_FX_1M)">Fx1m</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_FX_2M}"
            @click="changeKind(leisureConst.LEISURE_KIND_FX_2M)">Fx2m</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_FX_3M}"
            @click="changeKind(leisureConst.LEISURE_KIND_FX_3M)">Fx3m</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_FX_4M}"
            @click="changeKind(leisureConst.LEISURE_KIND_FX_4M)">Fx4m</button>
    <button class="btn02 bw5" :class="{'active': kind === leisureConst.LEISURE_KIND_FX_5M}"
            @click="changeKind(leisureConst.LEISURE_KIND_FX_5M)">Fx5m</button>
    <button class="btn02 bw5" style="background: transparent"></button>
  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import leisureConst from "../../common/leisureConst";

  export default {
    name: "LeisureGameLinksComp",
    data() {
      return {
        sportsConst,
        leisureConst,
        kind: leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,
      }
    },
    methods: {
      changeKind(kind) {
        //LeisureGameResult.vue -> $on
        this.kind = kind
        this.$emit('leisureKindChanged', kind);
      }
    }
  }
</script>

<style scoped>
  .kind-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .kind-links button {
    width: 33%;
    line-height: 24px;
    font-size: 12px;
    background: #2d2e35;
    border-radius: 3px;
    transition: 200ms all;
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 4px, rgb(255 255 255 / 22%) 1px 1px 4px -2px inset;
    color: #c8c8c7;
    font-weight: bold;
    margin-top: 3px;
  }

  .kind-links .active {
    color: #1c1c25 !important;
    font-size: 14px;
    font-weight: bold;
    background: linear-gradient(rgb(255, 182, 22) 0%, rgb(249, 201, 95) 100%) !important;
  }
</style>