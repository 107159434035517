<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn btn04 bw2" tag="button"  :to="{path:'/sports_result'}">{{i18nConst.GAME['SPORT_RESULT'][$store.state.country]}}</router-link>
        <router-link class="btn btn04 bw2" tag="button"  :to="{path:'/leisure_result'}">{{i18nConst.GAME['MINI_RESULT'][$store.state.country]}}</router-link>
    </div>
</template>

<script>
    import i18nConst from "../common/i18nConst";

    export default {
        name: "GameResultLinks",
        data() {
            return {
                i18nConst:i18nConst,
            }
        },

    }
</script>

<style scoped>

</style>